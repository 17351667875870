import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { CardProject } from '../../components/CardProject/CardProject';
import { StepperProgress } from '../../components/StepperProgress/StepperProgress';
import { useFormState } from '../../providers/formContext.provider';
import { ProjectSelectionStyles } from './ProjectSelection.styles';

const PROJECTS = [
  {
    id: 'p2o',
    imageSource: 'img/project_p2o.svg',
    title: 'Procure to Order',
    text: 'Coupa\'s P2O process enhances procurement efficiency, allowing quick response to immediate needs from need identification to order placement. It\'s tailored for agility, ensuring rapid requisition and order creation, which optimizes time and reduces operational bottlenecks.',
  },
  {
    id: 'p2p',
    imageSource: 'img/project_p2p.svg',
    title: 'Procure to Pay',
    text: 'The P2P cycle in Coupa integrates procurement with financial operations, streamlining the entire purchasing process. It enhances financial control by managing everything from order creation to payment, ensuring compliance, improving invoice accuracy, and optimizing cash flow.',
  },
  {
    id: 's2p',
    imageSource: 'img/project_s2p.svg',
    title: 'Source to Pay',
    text: 'Coupa\'s S2P provides a comprehensive spend management solution. It encompasses the full spectrum from supplier sourcing to payment, delivering strategic value through improved supplier relationships, better contract terms, and overall spend visibility. This process supports strategic decision-making and drives cost savings across the procurement lifecycle.',
  },
];

export const ProjectSelection = () => {
  const navigate = useNavigate();
  const { data, setData } = useFormState();
  const { state } = useLocation();

  const showStepper = useMemo(() => {
    return !state?.skipStepper;
  }, [state]);

  const handleOnClick = useCallback(() => {
    if (!data.email) {
      return navigate('/Login');
    }
  
    window.dataLayer.push({
      event: 'select_project',
      ...data
      });
    if (Boolean(data.project)) {
      if (!showStepper) {
        const routeMap = {
          p2o: 'pToO',
          p2p: 'pToP',
          s2p: 'sToP',
        };

        const url = `/${routeMap[data.project]}`;

        return navigate(url);
      }
      return navigate('/changeErp');
    }
  }, [data.project, navigate]);

  const handleCancelClick = useCallback(() => {
    setData(
      data
    );
    
    history.back();
  }, [setData]);

  useEffect(() => {
    if (!data.email) {
      navigate('/Login');
    }
  }, [navigate]);

  return (
    <Box sx={ProjectSelectionStyles.pageWrapper}>
      {
        !showStepper && (<Box sx={ProjectSelectionStyles.backButton}>
        <Link component="button" onClick={handleCancelClick}>
            <Grid container>
              <Grid item>
                <ChevronLeftIcon htmlColor="#fff"/>
              </Grid>
              <Grid item>
                <Typography color="#fff">
                  Cancel
                </Typography>
              </Grid>
            </Grid>
          </Link>
        </Box>)
      }
      <Grid alignItems="center" container direction="column" spacing={8} sx={ProjectSelectionStyles.container}>
        <Grid container justifyContent="center" item xs={12}>
          <Grid item xs={4}>
            {
              showStepper
              ? <StepperProgress/>
              : <Box sx={{padding: '.5rem'}}/>
            }
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" spacing={3}>
          {PROJECTS.map((item, index) => (
            <Grid item key={String(index)} xs={3}>
              <CardProject
                id={item.id}
                imageSource={item.imageSource}
                title={item.title}
                text={item.text}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={!data.project }
            onClick={handleOnClick}
            size="large"
            sx={ProjectSelectionStyles.button}
            variant="contained"
          >
            {
              showStepper
                ? "NEXT"
                : "SAVE"
            }
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
