import * as PropTypes from 'prop-types';
import React from 'react';
import ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import 'animate.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { FormContextProvider } from './providers/formContext.provider';
import App from './App';
import './style.css';

const app = document.getElementById('app');
const root = ReactDOMClient.createRoot(app);

// root.render(<VideoPopUp />);
root.render(
  <BrowserRouter>
    <FormContextProvider>
      <App/>
    </FormContextProvider>
  </BrowserRouter>,
);
