import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Modal,ImageList, ImageListItem, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useCallback, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import config from './introVideo.json';
import { Countdown } from '../../components/Countdown';

export const InfoPage = () => {
  const [isOpen, setOpen] = useState(false);
  const [isPostVideoModalOpen, setIsPostVideoModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleCloseVideo = useCallback(() => {
    setOpen(false);

    setTimeout(() => {
      navigate('/login');
    }, 250);
  }, [navigate]);

  const handleOnEnded = useCallback((...args) => {
    setIsPostVideoModalOpen(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 4000);
  }, []);

  return (
    <Grid
      alignItems="center"
      container
      justifyContent="center"
      sx={{ height: '100%', overflow: 'hidden', position: 'relative' }}
    >
      <Grid className="slide-intro" container item justifyContent="center">
        <img className="" alt="Logo trusted white" src="/img/logo-trusted-white-1.svg"/>
      </Grid>
      <Modal open={isOpen} onClose={handleCloseVideo}>
        <Grid
          alignItems="center"
          container
          justifyContent="center"
          sx={{ height: '100%', overflowY: 'auto', padding: '0 1rem', position: 'relative' }}
        >
          <ImageList sx={{ position: "absolute", left: "50%", top: "50%",transform: "translate(-50%, -50%)", height: "40px", width: "40px;" }} cols={1} rowHeight={40}>
            <ImageListItem>
              <img
                style={{ height: '100%', height: "auto"}}
                src='img/loading-white.gif'
                alt='Image'
                loading="lazy"
              />
            </ImageListItem>
          </ImageList>
          <Grid alignItems="center" container direction="column" spacing={1}>
            <Grid container direction="column" item spacing={1}>
              <Grid item>
                <Typography align="center" variant="h2" sx={{ color: 'white'}}>
                  {config.paragraphs[0].header}
                </Typography>
              </Grid>
              <Grid item>
                <Typography align="center" variant="h3" sx={{ color: 'white'}}>
                  {config.paragraphs[0].content}
                </Typography>
              </Grid>
            </Grid>
            </Grid>
          <Box sx={{ position: 'relative', width: '100%' }}>
            <ReactPlayer
              height="auto"
              onEnded={handleOnEnded}
              style={{
                margin: 'auto',
                maxWidth: '1250px',
                minWidth: '50%',
              }}
              url={config.video}
              width="100%"
            />
          </Box>
          <Box sx={{ position: 'absolute', top: '.5rem', right: '.5rem' }}>
            <IconButton
              aria-label="close"
              className="modal-close-button"
              onClick={handleCloseVideo}
              size="large"
            >
              <CloseIcon sx={{ color: '#fff' }}/>
            </IconButton>
          </Box>
        </Grid>
      </Modal>
      <Modal open={isPostVideoModalOpen}>
        <>
          <Countdown />
        </>
      </Modal>
    </Grid>
  );
};
