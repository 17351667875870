import React, { useContext, useState } from 'react';

const initialFormData = {
  email: '',
  name: '',
  company: '',
  consent: false,
  // 'PROCURE_TO_ORDER' | 'PROCURE_TO_PAY' | 'SOURCE_TO_PAY'
  project: '',
  // 'SAP ECC' | 'SAP S/4HANA' | 'Oracle' | 'Netsuite' | 'Microsoft Dynamics 365' | 'Sage X3' | 'free text'
  erp: [],
  erpOther: '',
};

const FormContext = React.createContext(null);

export const FormContextProvider = (props) => {
  const { children } = props;
  const [data, setData] = useState(initialFormData);

  return (
    <FormContext.Provider value={{ data, setData }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormState = () => {
  const context = useContext(FormContext);

  return context;
};
