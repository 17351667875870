import PropTypes from "prop-types";
import React, { useState }  from "react";
import ReactModal from 'react-modal';
import ReactPlayer from 'react-player';
import {VideoIcon} from "../../icons/VideoIcon";
import "./style.css";

export const TablerVideo1 = ({ color = "#031B78", className = "", src = 'https://video.vidyard.com/watch/XtGyX2dPttEyG8HFESvC3t', paragraphs = [], hoverStyleArray = [], isPlayerOpen = false}) => {
  const [isOpen, setOpen] = useState(isPlayerOpen);
  const [isHoverOpen, setHoverOpen] = useState(false);

  return (
    <React.Fragment>
      <ReactModal
           isOpen={isOpen}
           contentLabel="onRequestClose Example"
           onRequestClose={() => {setOpen(false);}}
           className="VideoModal animate__animated animate__fadeIn"
           ariaHideApp={false}
           shouldFocusAfterRender={false}
           overlayClassName="Overlay"
           parentSelector={
            // () => document.body.getElementsByClassName('app')[0]
            () => document.getElementById('app')
          }
        >
      <div className="box" onClick={() => {setOpen(false);}}>
        <img className="vector" alt="Vector" src="img/x.png" />
      </div>
      <div className="master-data-pop-up">
        <div className="player">
          <img className="loading-image " alt="Image" src="img/loading.gif" />
          <ReactPlayer style={{minHeight:'190px'}} width="100%" height="auto" url={src} />
        </div>
        <div className="text">
          {paragraphs.map((paragraph, index) => { return (
            <div className="paragraph" key={index}>
            <div className="text-wrapper">{paragraph.header}</div>
            <p className="div">{paragraph.content}</p>
          </div>
          )})}
        </div>
      </div>
      </ReactModal>
      <VideoIcon color={color} className={className} mouseOver={()=>setHoverOpen(true)} mouseLeave={()=>setHoverOpen(false)} click={() => setOpen(true)}></ VideoIcon>
      {isHoverOpen && <div className="hover-container">
        {
          hoverStyleArray.map((inlineStyle, index) => {
            return (
              <div className="single-rectangular" style={inlineStyle} key={index}>
              </div>
            )
          })
        }
         </div>  
}
    </React.Fragment>

  );
};

TablerVideo1.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
  paragraph: PropTypes.array
};
