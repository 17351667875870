import PropTypes from "prop-types";
import React, { useState } from "react";
import "./style.css";

const logoPossibeNames = ["erp_sap_hana", "erp_sap_ecc", "erp_dynamics_365", "erp_sage_x3", "erp_netsuite", "erp_oracle_suite"]

export const ErpComponent = ({ selectedLogoNames = []}) => {
  const [isOpen, setOpen] = useState(false);

  const hasToShowModeButton = (selectedLogoNames || []).length > 2;
  const modeElements = hasToShowModeButton ? selectedLogoNames.slice(2): [];

  function getLogo(logoName) {
    return !!logoPossibeNames.find((name) => name == logoName) ? (<img className="logo" alt={logoName} src={`/img/${logoName}.svg`} />) : (<p className="text-wrapper" title={logoName}>{logoName}</ p>)
  }

 function getMoreButton(index, hasToShowModeButton) {

  return ((index == 1) && hasToShowModeButton) && (
    <div className="button-wrapper">
      <button className="button">
        <div className="element-more" onMouseOver={()=>setOpen(true)} onMouseLeave={()=>setOpen(false)}>{modeElements.length} More</div>
      </button> 
    </div>
    )
 } 

  return (<>
<div className="ERP first">
      <div className="text-wrapper">ERP</div>
      {
        selectedLogoNames.slice(0,2).map((logoName, index) => {
          return (
            <div className="logo-container" key={index}>
              {getLogo(logoName)}
              {getMoreButton(index, hasToShowModeButton)}
            </div>
          )
        })
      }
    </div>
{isOpen && <div className="hover-ERP">
        {
          modeElements.map((logoName, index) => {
            return (
              <div className="frame" key={index}>
                {getLogo(logoName)}
              </div>
            )
          })
        }
         </div>   
      }
  </>
  );
}
