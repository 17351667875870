/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const MaterialSymbolsImageOutline = ({ className, click }) => {
  return (
    <svg
      className={`material-symbols-image-outline ${className}`}
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
      onClick={click}
    >
      <rect className="rect" fill="white" fillOpacity="0.2" height="28" rx="6" width="28" />
      <path
        className="path"
        d="M7 23C6.45 23 5.97933 22.8043 5.588 22.413C5.19667 22.0217 5.00067 21.5507 5 21V7C5 6.45 5.196 5.97933 5.588 5.588C5.98 5.19667 6.45067 5.00067 7 5H21C21.55 5 22.021 5.196 22.413 5.588C22.805 5.98 23.0007 6.45067 23 7V21C23 21.55 22.8043 22.021 22.413 22.413C22.0217 22.805 21.5507 23.0007 21 23H7ZM7 21H21V7H7V21ZM8 19H20L16.25 14L13.25 18L11 15L8 19Z"
        fill="white"
      />
    </svg>
  );
};
