{
    "master_data": [],
    "platform": [],
    "purchase_need": [{
        "width": "691px",
        "height": "128px",
        "top": "507px",
        "left": "257px",
        "border-top-left-radius": "20px",
        "border-bottom-left-radius": "20px",
        "border-top-right-radius": "20px",
        "z-index": 20
    }, {
        "width": "269px",
        "height": "338px",
        "top": "635px",
        "left": "713px",
        "border-bottom-left-radius": "20px",
        "border-bottom-right-radius": "20px",
        "border-top-right-radius": "20px",
        "z-index": 30
    }],
    "integration_layer": [],
    "ok_to_pay": []
}