export const StepperProgressStyles = {
  step: {
    backgroundColor: 'transparent',
    border: 'solid 1px #1ab4ff',
    borderRadius: 30,
    color: '#fff',
    padding: '1rem 2rem',
  },
  stepActive: {
    backgroundColor: '#fff',
    color: '#1b57b2',
  },
  stepItem: {
    color: '#fff',
  },
  stepItemActive: {
    backgroundColor: '#fff',
    color: '#1b57b2',
  },
  stepper: {
    width: '100%',
  },
};
