{
    "master_data": {
        "video": "https://video.vidyard.com/watch/G86dygv8TJc7BKmvVPowSR",
        "paragraphs": [{
            "header": "Master Data Integration", 
            "content": "See how comprehensive data integration streamlines the process."
        }, {
            "header": "User Data Synchronization", 
            "content": "Understand the importance of traceable actions and accountable transactions."
        },
        {
            "header": "Revolutionized Transactions", 
            "content": "Learn how Coupa embeds financial discipline into every transaction."
        }]
    },
    "platform": {
        "video": "https://video.vidyard.com/watch/CTB8wjbdk7mgRceq4YHLtN",
        "paragraphs": [{
            "header": "Revolutionary Platform", 
            "content": "Experience Coupa's powerful, streamlined Spend Management platform, offering robust, scalable cloud-native technology and seamless integration with your existing ERPs"
        }, {
            "header": "Flexible & Up-to-date System", 
            "content": "Discover the flexibility of Coupa's system with over 13 million configuration options and regular upgrades that enhance functionality without disrupting existing setups."
        },
        {
            "header": "Secure & Supportive Community", 
            "content": "Join a community dedicated to advancing spend management, with top-tier security certifications and a comprehensive marketplace of certified apps."
        }]
    },
    "purchase_need": {
        "video": "https://video.vidyard.com/watch/toDHdcKP6Xof8z6ERxYeLd",
        "paragraphs": [{
            "header": "Requisition and Purchasing Integration", 
            "content": "Transition from supplier approval to requisition process in Coupa."
        }, {
            "header": "Simplified Integration with Coupa's Portals", 
            "content": "Coupa's portals simplify integration with built-in forms, risk assessments and supplier communications."
        },
        {
            "header": "Seamless ERP Integration", 
            "content": "Integration with core financial systems for validating budgets and financial structures."
        }]
    },
    "integration_layer": {
        "video": "https://video.vidyard.com/watch/2XbmnR5SJgc8dLZyJLbioj",
        "paragraphs": [{
            "header": "Effortless Integration Insights", 
            "content": "Discover how Coupa's integration with ERP systems simplifies business processes, showcasing efficiency and ease."
        }, {
            "header": "Expert-Led Strategies", 
            "content": "Gain insights from Coupa's 15+ years of experience in ERP integration, offering proven strategies for successful implementation."
       
        }]
    },
    "ok_to_pay": {
        "video": "https://video.vidyard.com/watch/XXL32FTw4pVVQx8JaLQWaj",
        "paragraphs": [{
            "header": "Critical Invoice Matching", 
            "content": "Discover Coupa's precision in aligning invoices, orders, and receipts."
        }, {
            "header": "Efficient Payment Processes", 
            "content": "See how Coupa seamlessly processes payments and updates supplier status."
        },
        {
            "header": "Innovative Reporting Flexibility", 
            "content": "Explore Coupa’s reporting and analytics capabilities."
        }]
    }
}