import { Box } from '@mui/material';
import React, { useEffect } from "react";
import { Component } from "../../components/Component";
import { Frame } from "../../components/Frame";
import { FrameWrapper } from "../../components/FrameWrapper";
import { ErpComponent } from "../../components/ErpComponent";
import { ImageModal } from "../../components/ImageModal";
import { TablerVideo1 } from "../../components/TablerVideo1";
import { IntegrationStories } from "../../components/IntegrationStories";
import { Header } from "../../components/Header";
import config from './pToPconfig.json';
import styleConfig from './pToPhoverStyles.json';
import { useFormState } from '../../providers/formContext.provider';
import { useNavigate } from 'react-router-dom';

import "./style.css";

export const DiagramPtoP = () => {
  const navigate = useNavigate();
  const { data } = useFormState();

  useEffect(() => {
    if (!data.email) {
      navigate('/Login');
    }
  }, [navigate]);

  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
    <div className="diagram-ERP">
      <Box sx={{ height: '100%', margin: 'auto', position: 'relative', width: '1400px' }}>
      <div className="pp-diagram-ERP">
        <div className="div" />
        <div className="frame-2">
          <div className="frame-3">
            <ErpComponent
              selectedLogoNames={data.erpOther ? [...data.erp, data.erpOther] : [...data.erp]}
            >
            </ErpComponent>
            <div className="frame-4">
              <div className="div-wrapper">
                <div className="frame-5 first-with-zoom-in">
                  <div className="receive-purchase-2">Master Data</div>
                </div>
              </div>
              <div className="left">
                <Component className="component-2" property1="default" />
                <Frame
                  className="frame-instance invoice-payment eleventh-with-zoom-in"
                  receivePurchaseClassName="design-component-instance-node"
                  text={
                    <>
                      Invoice <br />
                      Payment
                    </>
                  }
                />
              </div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap-2">
              <FrameWrapper className="frame-15" />
              <div className="ph-users">
                <div className="text-wrapper-3">Integration layer</div>
              </div>
            </div>
          </div>
          <div className="frame-6">
            <div className="frame-7">
              <div className="ph-users-2 second">
                <img className="ph-book-open-text" alt="Ph book open text" src="/img/ph-book-open-text.svg" />
                <div className="integration-stories">
                  Integration <br />
                  Stories
                </div>
              </div>
              <div className="ph-users-2 third">
                <img className="vector" alt="Vector" src="/img/vector-1.svg" />
                <div className="text-wrapper-4">Platform</div>
              </div>
              <div className="ph-users-2 forth">
                <img className="fontisto-world-o" alt="Fontisto world o" src="/img/fontisto-world-o.svg" />
                <div className="global-support">
                  Global <br />
                  Support
                </div>
              </div>
            </div>
            <div className="left-2">
              <div className="frame-8">
                <Frame className="frame-9 third-with-zoom-in" receivePurchaseClassName="frame-10" text="Purchase Need" />
                <img className="line third-with-zoom-in" alt="Line" src="/img/line-8.svg" />
                <Frame className="frame-9 forth-with-zoom-in" receivePurchaseClassName="frame-10" text="Requisition" />
                <img className="line forth-with-zoom-in" alt="Line" src="/img/line-8.svg" />
                <Frame className="frame-9 fifth-with-zoom-in" receivePurchaseClassName="frame-10" text="Approval" />
                <img className="line fifth-with-zoom-in" alt="Line" src="/img/line-8.svg" />
                <Frame
                  className="frame-9 sixth-with-zoom-in"
                  receivePurchaseClassName="design-component-instance-node"
                  text={
                    <>
                      PO Issued <br />
                      to Supplier
                    </>
                  }
                />
                <img className="line sixth-with-zoom-in" alt="Line" src="/img/line-8.svg" />
                <Frame className="frame-9 seventh-with-zoom-in" receivePurchaseClassName="frame-10" text="Receipt" />
                <img className="line seventh-with-zoom-in" alt="Line" src="/img/line-8.svg" />
                <Frame
                  className="frame-9 eight-with-zoom-in"
                  receivePurchaseClassName="design-component-instance-node"
                  text={
                    <>
                      Invoice <br />
                      Received
                    </>
                  }
                />
                <img className="line eight-with-zoom-in" alt="Line" src="/img/line-8.svg" />
                <Frame
                  className="frame-9 nineth-with-zoom-in"
                  receivePurchaseClassName="design-component-instance-node"
                  text={
                    <>
                      Invoice <br />
                      Approved
                    </>
                  }
                />
              </div>
              <Frame
                className="frame-9 twelveth-with-zoom-in"
                receivePurchaseClassName="design-component-instance-node"
                text={
                  <>
                    Invoice <br />
                    Payment Details
                  </>
                }
              />
            </div>
          </div>
          <div className="frame-11">
            <div className="left-3">
              <Frame
                className="frame-12 tenth-with-zoom-in"
                receivePurchaseClassName="design-component-instance-node"
                text={
                  <>
                    Receive <br />
                    Purchase <br />
                    Order
                  </>
                }
              />
              <Frame
                className="frame-12 eleventh-with-zoom-in"
                receivePurchaseClassName="design-component-instance-node"
                text={
                  <>
                    Acknowledge
                    <br />
                    Purchase <br />
                    Order
                  </>
                }
              />
              <Frame
                className="frame-12 twelveth-with-zoom-in"
                receivePurchaseClassName="design-component-instance-node"
                text={
                  <>
                    Sends <br />
                    Invoice
                  </>
                }
              />
            </div>
            <Frame
              className="frame-13 thirtheen-with-zoom-in"
              receivePurchaseClassName="design-component-instance-node"
              text={
                <>
                  Invoice Status
                  <br />
                  Update
                </>
              }
            />
            <div className="ph-users-default-wrapper">
              <div className="ph-users-default fifth">
                <img className="vector-2" alt="Vector" src="/img/vector.svg" />
                <div className="text-wrapper-5">Suppliers</div>
              </div>
            </div>
          </div>
        </div>
        <img className="line-2 tenth-with-zoom-in" alt="Line" src="/img/line-7.svg" />
        <img className="line-3 eleventh-with-zoom-in" alt="Line" src="/img/line-7.svg" />
        <img className="arrow-long-bottom second-with-zoom-in" alt="Arrow long bottom" src="/img/arrow-long-bottom-6.svg" />
        <img className="arrow-long-bottom-2 long-eleventh-with-zoom-in" alt="Arrow long bottom" src="/img/arrow-long-bottom-5.svg" />
        <img className="arrow-long-bottom-3 long-thirtheen-with-zoom-in" alt="Arrow long bottom" src="/img/arrow-long-bottom-4.svg" />
        <img className="arrow-long-bottom-4 long-sixth-with-zoom-in" alt="Arrow long bottom" src="/img/arrow-long-bottom-3.svg" />
        <img className="arrow-long-bottom-5 long-twelveth-with-zoom-in" alt="Arrow long bottom" src="/img/arrow-long-bottom-2.svg" />
        <img className="arrow-long-bottom-6 long-nineth-with-zoom-in" alt="Arrow long bottom" src="/img/arrow-long-bottom-1.svg" />
        <img className="arrow-long-bottom-7 long-tenth-with-zoom-in" alt="Arrow long bottom" src="/img/arrow-long-bottom.svg" />
        <TablerVideo1 className="video-1" color="white" src={config.master_data.video} paragraphs={config.master_data.paragraphs}/>
        <TablerVideo1 className="video-2" color="white" src={config.ok_to_pay.video} paragraphs={config.ok_to_pay.paragraphs} hoverStyleArray={styleConfig.ok_to_pay}/>
        <TablerVideo1 className="video-3" color="white" src={config.purchase_need.video} paragraphs={config.purchase_need.paragraphs} hoverStyleArray={styleConfig.purchase_need}/>
        <IntegrationStories className="video-4" color="white"/>
        <TablerVideo1 className="video-5" color="white" src={config.platform.video} paragraphs={config.platform.paragraphs}/>
        <ImageModal className="material-symbols"/>
        <TablerVideo1 className="video-6" color="#031B78" src={config.integration_layer.video} paragraphs={config.integration_layer.paragraphs}/>
        <Box sx={{ position: 'relative', height: '50px' }}/>
      </div>
      </Box>
    </div>
    </Box>
  );
};
