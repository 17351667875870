export const CardProjectStyles = {
  button: {
    borderRadius: 30,
  },
  card: {
    borderRadius: 4.5,
    height: '100%',
    padding: '4rem 2rem',
  },
  cardActions: {
    justifyContent: 'center',
  },
  container: {
    height: '100%',
  },
  image: {
    height: '6.25rem',
    width: '8rem',
  },
};
