import { Grid, Paper, Typography } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CountdownStyles } from './Countdown.styles';

export const Countdown = () => {
  const [count, setCount] = useState(3);
  const navigate = useNavigate();

  useEffect(() => {
    if (count < 1) {
      navigate('/login');

      return;
    }

    const intervalId = setInterval(() => {
      setCount(prev => prev - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [count]);

  return (
    <Grid
      alignItems="center"
      container
      direction="column"
      justifyContent="center"
      sx={CountdownStyles.contentWrapper}
    >
      <Grid item>
        <Paper elevation={4} sx={CountdownStyles.countdownContent}>
          <Typography variant="h4">
            {`Starting your experience in ${count}...`}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};
