import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { StepperProgress } from '../../components/StepperProgress/StepperProgress';

import { useFormState } from '../../providers/formContext.provider';
import { DefineSetupDefaultStyles } from './DefineSetupDefault.styles';

const ERP = [
  {
    id: 'erp_sap_ecc',
    label: 'SAP ECC',
  },
  {
    id: 'erp_sap_hana',
    label: 'SAP S/4HANA',
  },
  {
    id: 'erp_oracle_suite',
    label: 'Oracle',
  },
  {
    id: 'erp_netsuite',
    label: 'Netsuite',
  },
  {
    id: 'erp_dynamics_365',
    label: 'Microsoft Dynamics 365',
  },
  {
    id: 'erp_sage_x3',
    label: 'Sage X3',
  },
];

export const DefineSetupDefault = () => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const { state } = useLocation();
  const otherOptionRef = useRef(null);
  const [error, setError] = useState(false);
  const { data, setData } = useFormState();
  const [isOtherOption, setIsOtherOption] = useState(Boolean(data.erpOther));

  const showStepper = useMemo(() => {
    return !state?.skipStepper;
  }, [state]);

  const handleCheckboxClick = useCallback((event) => {
    const { checked, id } = event.target;

    const nextErp = checked
      ? [...data.erp, id]
      : data.erp.filter((item) => item !== id);

    setData((prevState) => {
      return {
        ...prevState,
        erp: nextErp,
      }
    });
    setError(false);
  }, [data.erp, setData]);

  const handleCancelClick = useCallback(() => {
      setData(
        data
      );

      history.back();
    }, [setData]);

  const handleCheckboxOtherOptionClick = useCallback((event) => {
    const { checked } = event.target;

    if (!checked) {
      setData((prevState) => ({
        ...prevState,
        erpOther: '',
      }));
    }

    setIsOtherOption(checked);
    setError(false);
  }, [setData]);

  const handleInputBlur = useCallback((event) => {
    const { value } = event.target;

    event.target.value = String(value).trim();

    setData((prevState) => ({
      ...prevState,
      erpOther: event.target.value,
    }));
  }, []);

  const handleInputOtherOptionChange = useCallback((event) => {
    const { value } = event.target;

    setData((prevState) => ({
      ...prevState,
      erpOther: value,
    }));
    setError(!value);
  }, [setData]);

  const handleNextClick = useCallback(() => {
    if (!data.email) {
      return navigate('/Login');
    }

    setError(data.erp.length === 0 && !data.erpOther.trim());

    window.dataLayer.push({
      event: 'select_erps',
      ...data
    });
    if (data.project && (data.erp.length > 0 || data.erpOther.trim())) {
      const routeMap = {
        p2o: 'pToO',
        p2p: 'pToP',
        s2p: 'sToP',
      };

      const url = `/${routeMap[data.project]}`;

      navigate(url);
    }
  }, [data.erp, data.erpOther, data.project]);

  useEffect(() => {
    if (!data.email) {
      navigate('/Login');
    }
  }, [navigate]);

  return (
    <Box sx={DefineSetupDefaultStyles.pageWrapper}>
      {
        showStepper ? (<Box sx={DefineSetupDefaultStyles.backButton}>
        <Link to={"/selectProject"}>
          <Grid container>
            <Grid item>
              <ChevronLeftIcon htmlColor="#fff"/>
            </Grid>
            <Grid item>
              <Typography color="#fff">
                Back
              </Typography>
            </Grid>
          </Grid>
        </Link>
      </Box>)
      :(<Box sx={DefineSetupDefaultStyles.backButton}>
        <Link component="button" onClick={handleCancelClick}>
            <Grid container>
              <Grid item>
                <ChevronLeftIcon htmlColor="#fff"/>
              </Grid>
              <Grid item>
                <Typography color="#fff">
                  Cancel
                </Typography>
              </Grid>
            </Grid>
          </Link>
        </Box>)
      }
      <Grid alignItems="center" container justifyContent="center" sx={DefineSetupDefaultStyles.wrapper}>
        <Grid container direction="column" item spacing={8} sx={DefineSetupDefaultStyles.container} xs={4}>
          <Grid item xs={12}>
            {
              showStepper
                ? <StepperProgress/>
                : <Box sx={DefineSetupDefaultStyles.spacer}/>
            }
          </Grid>
          <Grid item>
            <Card sx={DefineSetupDefaultStyles.card}>
              <CardContent>
                <Grid container direction="column">
                  <Grid container direction="column" item spacing={8}>
                    <Grid item>
                      <Typography variant="h4">
                        ERP
                      </Typography>
                    </Grid>
                    <Grid item>
                      <form noValidate ref={formRef}>
                        <Grid container direction="column">
                          {
                            ERP.map((item) => (
                              <Grid item key={item.id}>
                                <FormControlLabel
                                  control={(
                                    <Checkbox
                                      checked={data.erp.includes(item.id)}
                                      id={item.id}
                                      onClick={handleCheckboxClick}
                                    />
                                  )}
                                  htmlFor={item.id}
                                  label={item.label}
                                />
                              </Grid>
                            ))
                          }
                          <Grid item>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  id="erp_other_option"
                                  checked={isOtherOption}
                                  onClick={handleCheckboxOtherOptionClick}
                                />
                              )}
                              htmlFor="erp_other_option"
                              label="Other"
                            />
                          </Grid>
                            <Grid container direction="column" item>
                              <Grid item>
                              {
                                isOtherOption
                                  ? <TextField
                                    InputProps={{ sx: DefineSetupDefaultStyles.textField }}
                                    error={error}
                                    fullWidth
                                    value={data.erpOther}
                                    inputRef={otherOptionRef}
                                    onBlur={handleInputBlur}
                                    onChange={handleInputOtherOptionChange}
                                    placeholder="Enter ERP"
                                    required
                                  />
                                  : <Box sx={DefineSetupDefaultStyles.otherSpacer}/>
                                }
                              </Grid>
                            </Grid>
                        </Grid>
                      </form>
                    </Grid>
                  </Grid>
                  {error && (
                    <Grid item>
                      <FormHelperText error>Please select at least one option or define custom one</FormHelperText>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid container item justifyContent="center" xs={12}>
            <Button
              onClick={handleNextClick}
              size="large"
              sx={DefineSetupDefaultStyles.button}
              variant="contained"
            >
              {
                showStepper
                  ? "NEXT"
                  : "SAVE"
              }
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
