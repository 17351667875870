{
    "master_data": [],
    "sourcing": [{
        "width": "160px",
        "height": "318px",
        "top": "191px",
        "left": "415px",
        "z-index": 20
    },{
        "width": "345px",
        "height": "460px",
        "top": "509px",
        "left": "250px",
        "border-radius": "20px",
        "z-index": 20
    }],
    "platform": [],
    "purchase_need": [{
        "width": "505px",
        "height": "128px",
        "top": "507px",
        "left": "598px",
        "border-top-left-radius": "20px",
        "border-bottom-left-radius": "20px",
        "border-top-right-radius": "20px",
        "z-index": 20
    }, {
        "width": "212px",
        "height": "334px",
        "top": "635px",
        "left": "913px",
        "border-bottom-left-radius": "20px",
        "border-bottom-right-radius": "20px",
        "border-top-right-radius": "20px",
        "z-index": 30
    }],
    "integration_layer": [],
    "ok_to_pay": [{
        "width": "357px",
        "height": "120px",
        "top": "75px",
        "left": "1057px",
        "border-top-left-radius": "20px",
        "border-top-right-radius": "20px",
        "border-bottom-left-radius": "20px"
    },
    {
        "width": "310px",
        "height": "576px",
        "top": "195px",
        "left": "1104px",
        "border-top-left-radius": "20px",
        "border-bottom-left-radius": "20px"
    },
    {
        "width": "293px",
        "height": "200px",
        "top": "771px",
        "left": "1121px",
        "border-bottom-left-radius": "20px",
        "border-bottom-right-radius": "20px"
    }]
}