import { keyframes } from '@mui/system';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const CountdownStyles = {
  countdownContent: {
    backgroundColor: 'rgba(0, 0, 0, .4)',
    borderRadius: '3rem',
    boxShadow: '0 0 20px 20px rgba(0, 0, 0, .4)',
    color: 'var(--white)',
    opacity: 0,
    padding: '2rem 4rem',
    animation: `${fadeIn} .25s linear forwards`
  },
  contentWrapper: {
    backdropFilter: 'blur(1rem)',
    height: '100%',
    opacity: 0,
    animation: `${fadeIn} .25s linear forwards`
  },
}
