import PropTypes from "prop-types";
import React, { useState }  from "react";
import ReactModal from 'react-modal';
import {MaterialSymbolsImageOutline} from "../../icons/MaterialSymbolsImageOutline";

export const ImageModal = ({ color = "#031B78", className, src = '/img/global_customer_support.png' }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <React.Fragment>
      <ReactModal
           isOpen={isOpen}
           contentLabel="onRequestClose Example"
           onRequestClose={() => {setOpen(false);}}
           className="ImageModal animate__animated animate__fadeIn"
           ariaHideApp={false}
           shouldFocusAfterRender={false}
           overlayClassName="Overlay"
           parentSelector={
             // () => document.body.getElementsByClassName('app')[0]
             () => document.getElementById('app')
          }
        >
          <div className="box" onClick={() => {setOpen(false);}}>
            <img className="vector" alt="Vector" src="img/x.png" />
          </div>
          <img style={{width: "1157px", height: "653px"}} className="img" alt="Image" src={src} />
      </ReactModal>
      <MaterialSymbolsImageOutline color={color} className={className} click={() => setOpen(true)}></ MaterialSymbolsImageOutline>
    </React.Fragment>

  );
};

ImageModal.propTypes = {
  color: PropTypes.string,
  src: PropTypes.string,
};
