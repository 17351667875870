import {
  Box,
  Step,
  Stepper,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { StepperProgressStyles } from './StepperProgress.styles';

const STEPS = [
  'Select a Project',
  'Define Setup',
];

const StepItem = (props) => {
  const { active, step, text } = props;


  const styles = {
    ...StepperProgressStyles.stepItem,
    ...(active ? StepperProgressStyles.stepItemActive : {}),
  };

  return (
    <Box sx={styles}>
      <Typography>
        {`0${step} - ${text}`}
      </Typography>
    </Box>
  );
};

export const StepperProgress = () => {
  const { pathname } = useLocation();

  const steps = useMemo(() => {
    return STEPS.map((item, index) => {
      const activeTabMap = [
        String(pathname).includes('selectProject'),
        String(pathname).includes('changeErp')
      ];

      return {
        active: activeTabMap[Number(index)],
        text: item,
      };
    });
  }, [pathname]);

  return (
    <Stepper sx={StepperProgressStyles.stepper}>
      {steps.map((item, index) => {
        const { active, text } = item;
        const styles = {
          ...StepperProgressStyles.step,
          ...(active ? StepperProgressStyles.stepActive : {}),
        };

        return (
          <Step key={text} completed={false} sx={styles}>
            <StepItem active={active} text={text} step={index + 1}/>
          </Step>
        );
      })}
    </Stepper>
  );
};
