import {
  Avatar,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormState } from '../../providers/formContext.provider';

import { FormIdentityStyles } from './FormIdentity.styles';

const CONSENT_LABEL = 'By submitting this form, you agree that Coupa Software, Inc. may contact you occasionally via email or phone to make you aware of Coupa products and services. You may withdraw your consent at any time. For more details see the Coupa';
const CONSENT_LABEL_PRIVACY_POLICY_TEXT = 'Privacy Policy.';
const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // https://emailregex.com/index.html

export const FormIdentity = () => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    company: false,
    consent: false,
  });
  const { data, setData } = useFormState();

  const handleNextClick = useCallback(() => {
    const {
      name,
      email,
      company,
      consent,
    } = formRef.current;

    const isNameError = name.value.trim().length <= 0;
    const isEmailError = email.value.length <= 0 || !EMAIL_PATTERN.test(email.value);
    const isCompanyError = company.value.trim().length <= 0;
    const isConsentError = !consent.checked;

    setErrors({
      name: isNameError,
      email: isEmailError,
      company: isCompanyError,
      consent: isConsentError,
    });

    if (!isNameError && !isEmailError && !isCompanyError && !isConsentError) {
      setData((prevState) => ({
        ...prevState,
        name: name.value.trim(),
        email: email.value.trim(),
        company: company.value.trim(),
        consent: consent.checked,
      }));
      window.dataLayer.push({
        event: 'register_company',
        name: name.value.trim(),
        email: email.value.trim(),
        company: company.value.trim(),
        consent: consent.checked,
      });
      navigate('/selectProject');
    }
  }, [navigate]);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;

    if (value.trim()) {
      setErrors((prevState) => ({
        ...prevState,
        [name]: false,
      }));
    }
  }, []);

  const handleInputBlur = useCallback((event) => {
    const { value } = event.target;

    event.target.value = String(value).trim();
  }, []);

  return (
    <Card sx={FormIdentityStyles.card}>
      <Grid alignItems="center" container direction="column" spacing={4}>

        <Grid container direction="column" item spacing={2}>
          <Grid container item justifyContent="center">
            <Avatar src="https://c.animaapp.com/yVStWHVM/img/vector.svg" sx={FormIdentityStyles.logo}/>
          </Grid>
          <Grid item>
            <Typography align="center" variant="h4">
              Welcome to the
              <br/>
              Interactive Integration Experience
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <form noValidate ref={formRef}>
            <Grid justifyContent="center" container spacing={4}>
              <Grid container item spacing={4} xs={12}>
                <Grid container item spacing={1} xs={12}>
                  <Grid item xs={12}>
                    <FormLabel
                      error={errors.name}
                      id="identity_form_name"
                      required
                    >
                      Name
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputProps={{ sx: FormIdentityStyles.textField }}
                      error={errors.name}
                      fullWidth
                      id="identity_form_name"
                      name="name"
                      onBlur={handleInputBlur}
                      onChange={handleInputChange}
                      required
                      defaultValue={data.name || ''}
                    />
                  </Grid>
                  {errors.name && (
                    <Grid item xs={12}>
                      <FormHelperText error>Please provide a name</FormHelperText>
                    </Grid>
                  )}
                </Grid>
                <Grid container item spacing={1} xs={12}>
                  <Grid item xs={12}>
                    <FormLabel
                      error={errors.email}
                      id="identity_form_email"
                      required
                    >
                      Email
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputProps={{ sx: FormIdentityStyles.textField }}
                      error={errors.email}
                      fullWidth
                      id="identity_form_email"
                      name="email"
                      onBlur={handleInputBlur}
                      onChange={handleInputChange}
                      required
                      defaultValue={data.email || ''}
                    />
                  </Grid>
                  {errors.email && (
                    <Grid item xs={12}>
                      <FormHelperText error>Please provide an email</FormHelperText>
                    </Grid>
                  )}
                </Grid>
                <Grid container item spacing={1} xs={12}>
                  <Grid item xs={12}>
                    <FormLabel
                      error={errors.company}
                      id="identity_form_company"
                      required
                    >
                      Company
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputProps={{ sx: FormIdentityStyles.textField }}
                      error={errors.company}
                      fullWidth
                      id="identity_form_company"
                      name="company"
                      onBlur={handleInputBlur}
                      onChange={handleInputChange}
                      required
                      defaultValue={data.company || ''}
                    />
                  </Grid>
                  {errors.company && (
                    <Grid item xs={12}>
                      <FormHelperText error>Company name is required</FormHelperText>
                    </Grid>
                  )}
                </Grid>
                <Grid container item spacing={1} xs={12}>
                  <FormControlLabel
                    htmlFor="identity_form_consent"
                    required
                    control={(
                      <Checkbox
                        defaultChecked={Boolean(data.consent)}
                        id="identity_form_consent"
                        name="consent"
                        onChange={handleInputChange}
                      />
                    )}
                    label={(
                      <>
                        <Typography>{CONSENT_LABEL}</Typography>
                        <Link to="https://www.coupa.com/privacy-policy" target="_blank">{CONSENT_LABEL_PRIVACY_POLICY_TEXT}</Link>
                      </>
                    )}
                  />
                  {errors.consent && (
                    <FormHelperText error>
                      You must agree to Coupa Software Privacy Policy
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  onClick={handleNextClick}
                  size="large"
                  sx={FormIdentityStyles.button}
                  variant="contained"
                >
                  Enter
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Card>
  );
};
