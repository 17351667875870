import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Header } from './components/Header';

import { DefineSetupDefault } from './screens/DefineSetupDefault';
import { DiagramPtoO } from './screens/DiagramPtoO';
import { DiagramPtoP } from './screens/DiagramPtoP';
import { DiagramStoP } from './screens/DiagramStoP';
import { InfoPage } from './screens/InfoPage';
import { Login } from './screens/Login';
import { ProjectSelection } from './screens/ProjectSelection';

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout/>}>
        <Route index element={<InfoPage/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/selectProject" element={<ProjectSelection/>}/>
        <Route path="/changeErp" element={<DefineSetupDefault/>}/>
        <Route path="pToP" element={(
          <>
            <Header />
            <DiagramPtoP/>
          </>
        )}/>
        <Route path="pToO" element={(
          <>
            <Header />
            <DiagramPtoO/>
          </>
        )}/>
        <Route path="sToP" element={(
          <>
            <Header />
            <DiagramStoP/>
          </>
        )}/>
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <Outlet/>
  );
}
