import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ReactModal from 'react-modal';
import "./style.css";

export const HeaderBurger = () => {
  const [isOpen, setOpen] = useState(false);
  const [isSharedWebSite, setOpenSharedModal] = useState(false);
  const [copiedLink, copyLink] = useState(false);
  
  return (
    <>
      <ReactModal
        isOpen={isSharedWebSite}
        contentLabel="onRequestClose Example"
        onRequestClose={() => {setOpenSharedModal(false);copyLink(false);}}
        className="VideoModal animate__animated animate__fadeIn"
        ariaHideApp={false}
        shouldFocusAfterRender={false}
        overlayClassName="menu-overlay"
        parentSelector={
          // () => document.body.getElementsByClassName('app')[0]
          () => document.getElementById('app')
        }
      >
         <div className="box copy-link" onClick={() => {setOpenSharedModal(false);copyLink(false);}}>
            <img className="vector" alt="Vector" src="img/x.png" />
          </div>
        <div className="share-pop-up">
          <div className="title">
            <div className="text-wrapper">Coupa</div>
          </div>
          <input className="input" disabled placeholder="https://integrationexperience.coupa.com"/>
          {copiedLink ?
            (<div className="frame">
              <img className="ep-success-filled" alt="Ep success filled" src="/img/ep_success-filled.svg" />
              <div className="text-wrapper">COPIED</div>
            </ div>):
            (<div className="frame pointer" onClick={() => {navigator.clipboard.writeText("https://integrationexperience.coupa.com");copyLink(true)}}><div className="div">COPY LINK</div></div>)
          }
        </div>
      </ReactModal>

      <ReactModal
           isOpen={isOpen}
           contentLabel="onRequestClose Example"
           onRequestClose={() => {setOpen(false);}}
           className="BurgerModal animate__animated animate__fadeIn"
           ariaHideApp={false}
           shouldFocusAfterRender={false}
           overlayClassName="menu-overlay"
           parentSelector={
             // () => document.body.getElementsByClassName('app')[0]
             () => document.getElementById('app')
          }
        >
        <div className="frame-screen">
          <NavLink to="/selectProject" state={{ skipStepper:true }}>
            <div className="frame">
              <div className="text-wrapper">Change Project</div>
            </div>
          </NavLink>
          <NavLink to="/changeErp" state={{ skipStepper:true }}>
          <div className="frame">
            <div className="text-wrapper">Change ERP</div>
            </div>
          </NavLink>
          <div className="frame" onClick={() => {setOpen(false); setOpenSharedModal(true);}}>
            <div className="text-wrapper">Invite to Coupa Integration Experience</div>
          </div>
          <a href="https://compass.coupa.com/en-us/products/product-documentation/integration-technical-documentation" target="_blank"><div className="frame"><div className="text-wrapper">Technical Documentation</div></div></a>
          <a href="https://www.coupa.com/platform/erp-integration" target="_blank"><div className="frame"><div className="text-wrapper">Coupa Integration Webpage</div></div></a>
        </div>
      </ReactModal>
      <div className="right-group" onClick={() => setOpen(!isOpen)}>
        <img className="material-symbols-2" alt="Material symbols" src="/img/burger.svg" />
      </div>
    </>
  );
};
