/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Component = ({ property1, className }) => {
  return (
    <div className={`component tenth-with-zoom-in ${className}`}>
      <div className={`overlap-group ${property1}`}>
        <div className="rectangle" />
        <div className="receive-purchase-wrapper">
          <div className="receive-purchase">OK to Pay Invoices</div>
        </div>
      </div>
    </div>
  );
};

Component.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "variant-3", "default"]),
};
