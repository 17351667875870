export const DefineSetupDefaultStyles = {
  backButton: {
    color: 'white',
    position: 'absolute',
    top: '4rem',
    left: '1.5rem',
  },
  button: {
    backgroundColor: '#1ab4ff',
    borderRadius: 30,
    color: '#fff',
    padding: '1rem 8rem',
  },
  card: {
    padding: '5.44rem 3.5rem',
    borderRadius: 4.5,
  },
  container: {
    paddingBottom: '4rem',
    position: 'relative',
  },
  pageWrapper: {
    height: '100%',
    overflowY: 'auto',
    padding: '1rem 0',
    position: 'relative',
  },
  spacer: {
    padding: '.5rem',
  },
  otherSpacer: {
    padding: '1.72rem',
  },
  textField: {
    borderRadius: 30,
  },
  wrapper: {
    height: '100%',
  },
};
