import { Box } from '@mui/material';
import React, { useEffect } from "react";
import { Component } from "../../components/Component";
import { Frame } from "../../components/Frame";
import { FrameWrapper } from "../../components/FrameWrapper";
import { ErpComponent } from "../../components/ErpComponent";
import { ImageModal } from "../../components/ImageModal";
import { TablerVideo1 } from "../../components/TablerVideo1";
import { IntegrationStories } from "../../components/IntegrationStories";

import config from './sToPconfig.json';
import styleConfig from './sToPhoverStyles.json';

import { useFormState } from '../../providers/formContext.provider';
import { useNavigate } from 'react-router-dom';

import "./style.css";


export const DiagramStoP = () => {
  const navigate = useNavigate();
  const { data } = useFormState();

  useEffect(() => {
    if (!data.email) {
      navigate('/Login');
    }
  }, [navigate]);

  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
    <Box sx={{ height: '100%', margin: 'auto', position: 'relative', width: '1400px' }}>
    <div className="diagram-updated">
      <div className="frame-2">
        <div className="frame-3">
          <ErpComponent
            selectedLogoNames={data.erpOther ? [...data.erp, data.erpOther] : [...data.erp]}
          >
          </ErpComponent>
          <div className="group" />
          <div className="frame-4">
            <div className="div-wrapper first-with-zoom-in">
              <div className="receive-purchase-2">Master Data</div>
            </div>
            <div className="left">
              <Component className="component-2 thirteenth-with-zoom-in" property1="default" />
              <Frame
                className="frame-instance forteenth-with-zoom-in"
                receivePurchaseClassName="design-component-instance-node"
                text={
                  <>
                    Invoice
                    <br />
                    Payment
                  </>
                }
              />
            </div>
          </div>
        </div>
        <div className="overlap-wrapper">
          <div className="overlap-2">
            <FrameWrapper className="frame-15" />
            <div className="ph-users">
              <div className="middleware">Integration layer</div>
            </div>
          </div>
        </div>
        <div className="frame-5">
          <div className="frame-6">
            <div className="ph-users-2 second">
              <img className="ph-book-open-text" alt="Ph book open text" src="/img/ph-book-open-text.svg" />
              <div className="integration-stories">
                Integration <br />
                Stories
              </div>
            </div>
            <div className="ph-users-2 third">
              <img className="vector" alt="Vector" src="/img/vector-1.svg" />
              <div className="text-wrapper-3">Platform</div>
            </div>
            <div className="ph-users-2 forth">
              <img className="fontisto-world-o" alt="Fontisto world o" src="/img/fontisto-world-o.svg" />
              <div className="global-support">
                Global <br />
                Support
              </div>
            </div>
          </div>
          <div className="left-2">
            <div className="frame-7">
              <Frame className="frame-8 third-with-zoom-in" receivePurchaseClassName="frame-9" text="Sourcing" />
              <img className="line third-with-zoom-in" alt="Line" src="/img/s-to-p-line-11.svg" />
              <Frame className="frame-10 forth-with-zoom-in" receivePurchaseClassName="frame-9" text="Contracting" />
              <img className="line forth-with-zoom-in" alt="Line" src="/img/s-to-p-line-11.svg" />
              <Frame
                className="frame-11 fifth-with-zoom-in"
                receivePurchaseClassName="frame-12"
                text={
                  <>
                    Supplier
                    <br />
                    Onboarding &amp;
                    <br />
                    Management
                  </>
                }
              />
              <img className="line fifth-with-zoom-in" alt="Line" src="/img/s-to-p-line-11.svg" />
              <Frame
                className="frame-11 sixth-with-zoom-in"
                receivePurchaseClassName="frame-12"
                text={
                  <>
                    Purchase <br />
                    Need
                  </>
                }
              />
              <img className="line sixth-with-zoom-in" alt="Line" src="/img/s-to-p-line-11.svg" />
              <Frame className="frame-11 seventh-with-zoom-in" receivePurchaseClassName="frame-9" text="Requisition" />
              <img className="line seventh-with-zoom-in" alt="Line" src="/img/s-to-p-line-11.svg" />
              <Frame className="frame-11 eight-with-zoom-in" receivePurchaseClassName="frame-9" text="Approval" />
              <img className="line eight-with-zoom-in" alt="Line" src="/img/s-to-p-line-11.svg" />
              <Frame
                className="frame-11 nineth-with-zoom-in"
                receivePurchaseClassName="frame-12"
                text={
                  <>
                    PO Issued <br />
                    to Supplier
                  </>
                }
              />
              <img className="line nineth-with-zoom-in" alt="Line" src="/img/s-to-p-line-11.svg" />
              <Frame className="frame-11 tenth-with-zoom-in" receivePurchaseClassName="frame-9" text="Receipt" />
              <img className="line tenth-with-zoom-in" alt="Line" src="/img/s-to-p-line-11.svg" />
              <Frame
                className="frame-11 eleventh-with-zoom-in"
                receivePurchaseClassName="frame-12"
                text={
                  <>
                    Invoice
                    <br />
                    Recieved
                  </>
                }
              />
              <img className="line eleventh-with-zoom-in" alt="Line" src="/img/s-to-p-line-11.svg" />
              <Frame
                className="frame-11 twelveth-with-zoom-in"
                receivePurchaseClassName="frame-12"
                text={
                  <>
                    Invoice <br />
                    Approved
                  </>
                }
              />
            </div>
            <Frame
              className="frame-13 fifteenth-with-zoom-in"
              receivePurchaseClassName="frame-12 "
              text={
                <>
                  Invoice <br />
                  Payment <br />
                  Details
                </>
              }
            />
          </div>
        </div>
        <div className="frame-14">
          <div className="left-3" />
          <Frame
            className="frame-16 sixteenth-with-zoom-in"
            receivePurchaseClassName="frame-17"
            text={
              <>
                Invoice Status
                <br />
                Update
              </>
            }
          />
          <div className="ph-users-default-wrapper">
            <div className="ph-users-default fifth">
              <img className="vector-2" alt="Vector" src="/img/vector.svg" />
              <div className="text-wrapper-4">Suppliers</div>
            </div>
          </div>
        </div>
      </div>
      <TablerVideo1 className="tabler-video" color="white" src={config.sourcing.video} paragraphs={config.sourcing.paragraphs} hoverStyleArray={styleConfig.sourcing}/>
      <TablerVideo1 className="tabler-video-3" color="white" src={config.purchase_need.video} paragraphs={config.purchase_need.paragraphs} hoverStyleArray={styleConfig.purchase_need}/>
      <TablerVideo1 className="tabler-video-instance" color="white" src={config.ok_to_pay.video} paragraphs={config.ok_to_pay.paragraphs} hoverStyleArray={styleConfig.ok_to_pay}/>
      <div className="frame-18">
        <Frame
          className="frame-19 thirteenth-with-zoom-in"
          receivePurchaseClassName="frame-20"
          text={
            <>
              Receive <br />
              Purchase <br />
              Order
            </>
          }
        />
        <img className="line-2 thirteenth-with-zoom-in" alt="Line" src="/img/s-to-p-line-7.svg" />
        <Frame
          className="frame-21 forteenth-with-zoom-in"
          receivePurchaseClassName="frame-12"
          text={
            <>
              Acknowledge
              <br />
              Purchase <br />
              Order
            </>
          }
        />
        <img className="line-2 forteenth-with-zoom-in" alt="Line" src="/img/s-to-p-line-7.svg" />
        <Frame
          className="frame-19 fifteenth-with-zoom-in"
          receivePurchaseClassName="frame-12"
          text={
            <>
              Sends <br />
              Invoice
            </>
          }
        />
      </div>
      <div className="frame-22">
        <Frame
          className="frame-16 third-with-zoom-in"
          receivePurchaseClassName="frame-12"
          text={
            <>
              RFX
              <br />
              Responses
            </>
          }
        />
        <Frame
          className="frame-16 forth-with-zoom-in"
          receivePurchaseClassName="frame-12"
          text={
            <>
              Supplier Info
              <br />
              From
              <br />
              Resonses
            </>
          }
        />
        <Frame
          className="frame-16 fifth-with-zoom-in"
          receivePurchaseClassName="frame-12"
          text={
            <>
              Contract
              <br />
              Collaboration, <br />
              Redline,
              <br />
              Signature
            </>
          }
        />
      </div>
      <TablerVideo1 className="tabler-video-3-instance" color="white" src={config.master_data.video} paragraphs={config.master_data.paragraphs}/>
      <div className="frame-23">
        <IntegrationStories className="icon-instance-node" color="white" />
      </div>
      <img className="arrow-long-bottom long-forteenth-with-zoom-in" alt="Arrow long bottom" src="/img/s-to-p-arrow-long-bottom-10.svg" />
      <img className="arrow-long-bottom-2 long-fifth-with-zoom-in" alt="Arrow long bottom" src="/img/s-to-p-arrow-long-bottom-9.svg" />
      <img className="arrow-long-bottom-3 long-fifth-with-zoom-in" alt="Arrow long bottom" src="/img/s-to-p-arrow-long-bottom-8.svg" />
      <img className="arrow-long-bottom-4 long-forth-with-zoom-in" alt="Arrow long bottom" src="/img/s-to-p-arrow-long-bottom-7.svg" />
      <img className="arrow-long-bottom-5 long-third-with-zoom-in" alt="Arrow long bottom" src="/img/s-to-p-arrow-long-bottom-6.svg" />
      <img className="arrow-long-bottom-6 long-fifteenth-with-zoom-in" alt="Arrow long bottom" src="/img/s-to-p-arrow-long-bottom-5.svg" />
      <img className="arrow-long-bottom-7 long-nineth-with-zoom-in" alt="Arrow long bottom" src="/img/s-to-p-arrow-long-bottom-4.svg" />
      <img className="arrow-long-bottom-8 long-twelveth-with-zoom-in" alt="Arrow long bottom" src="/img/s-to-p-arrow-long-bottom-3.svg" />
      <img className="arrow-long-bottom-9 long-sixteenth-with-zoom-in" alt="Arrow long bottom" src="/img/s-to-p-arrow-long-bottom-2.svg" />
      <img className="arrow-long-bottom-10 long-thirteenth-with-zoom-in" alt="Arrow long bottom" src="/img/s-to-p-arrow-long-bottom-1.svg" />
      <img className="arrow-long-bottom-11 second-with-zoom-in" alt="Arrow long bottom" src="/img/s-to-p-arrow-long-bottom.svg" />
      <TablerVideo1 className="tabler-video-2" color="white" src={config.platform.video} paragraphs={config.platform.paragraphs}/>
      <ImageModal className="material-symbols" />
      <TablerVideo1 className="tabler-video-4" color="#031B78" src={config.integration_layer.video} paragraphs={config.integration_layer.paragraphs}/>
      <Box sx={{ position: 'relative', height: '50px' }}/>
    </div>
    </Box>
    </Box>
  );
};
