/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React  from "react";

import "./style.css";

export const VideoIcon = ({ color = "#031B78", className, click, mouseOver, mouseLeave}) => {
  return (
    <svg
      className={`tabler-video-1 ${className}`}
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
      onClick={click}
      onMouseOver={mouseOver}
      onMouseLeave={mouseLeave}
    >
      <rect className="rect" fill={color} fillOpacity="0.2" height="28" rx="6" width="28" />
      <path
        className="path"
        d="M17 12L21.553 9.724C21.7054 9.64784 21.8748 9.61188 22.045 9.61955C22.2152 9.62721 22.3806 9.67825 22.5256 9.76781C22.6706 9.85736 22.7902 9.98248 22.8733 10.1313C22.9563 10.2801 22.9999 10.4476 23 10.618V17.382C22.9999 17.5524 22.9563 17.7199 22.8733 17.8687C22.7902 18.0175 22.6706 18.1426 22.5256 18.2322C22.3806 18.3218 22.2152 18.3728 22.045 18.3805C21.8748 18.3881 21.7054 18.3522 21.553 18.276L17 16V12ZM5 10C5 9.46957 5.21071 8.96086 5.58579 8.58579C5.96086 8.21071 6.46957 8 7 8H15C15.5304 8 16.0391 8.21071 16.4142 8.58579C16.7893 8.96086 17 9.46957 17 10V18C17 18.5304 16.7893 19.0391 16.4142 19.4142C16.0391 19.7893 15.5304 20 15 20H7C6.46957 20 5.96086 19.7893 5.58579 19.4142C5.21071 19.0391 5 18.5304 5 18V10Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>    
  );
};

VideoIcon.propTypes = {
  color: PropTypes.string,
  src: PropTypes.string,
};
