export const FormIdentityStyles = {
  button: {
    borderRadius: 30,
    padding: '1.125rem 0',
  },
  card: {
    padding: '4rem 3rem',
  },
  logo: {
    height: 65,
    width: 72,
  },
  textField: {
    borderRadius: 30,
  },
};
