export const ProjectSelectionStyles = {
  backButton: {
    color: 'white',
    position: 'absolute',
    top: '4rem',
    left: '1.5rem',
    zIndex: 2,
  },
  button: {
    borderRadius: 30,
    padding: '1rem 8rem',
  },
  container: {
    position: 'relative',
  },
  pageWrapper: {
    height: '100%',
    overflowY: 'auto',
    padding: '1rem 0',
    position: 'relative',
  }
};
