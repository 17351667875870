{
    "erp_sap_ecc": {
        "label": "SAP ECC",
        "data": [{
            "type": "video",
            "url": "https://video.vidyard.com/watch/r59zdGBmF179GFPjncmPYi",
            "header": " Discover how Zurich Insurance Group has transformed their business spending management, revolutionizing the way they operate and setting new standards in the industry."
        },{
            "type": "video",
            "url": "https://video.vidyard.com/watch/WvkQ3qapFsW7NntqL1X3FD",
            "header": "Unlock the secrets behind Novo Nordisk's innovative approach to financial management that has catapulted them to the forefront of the healthcare industry. "
        },{
            "type": "pdf",
            "png": "/pdf/ecc/quates.png",
            "url": "/pdf/ecc/quates.pdf",
            "header": "Explore the transformative power of Coupa through the eyes of IT leaders"
        },{
            "type": "pdf",
            "png": "/pdf/ecc/standard.png",
            "url": "/pdf/ecc/standard.pdf",
            "header": "Discover how Cooper Standard, a leader in automotive system manufacturing, leveraged Coupa to achieve unprecedented efficiency in business spend management."
        },{
            "type": "pdf",
            "png": "/pdf/ecc/sap.png",
            "url": "/pdf/ecc/sap.pdf",
            "header": "Coupa customers with SAP"
        }]
    },
    "erp_sap_hana": {
        "label": "SAP S/4HANA",
        "data": [{
            "type": "video",
            "url": "https://video.vidyard.com/watch/q5JfS73jHa9FzJJRcxMs5c",
            "header": " Dive into the compelling story of how AstraZeneca leveraged Coupa to transform their business spend management."
        },{
            "type": "video",
            "url": "https://video.vidyard.com/watch/ZYs1vuqvYRSZdwmGS94UJq",
            "header": "Embark on a journey with Barclays as they share their transformative experience with Coupa "
        },{
            "type": "video",
            "url": "https://video.vidyard.com/watch/VsnR4YopY6eKJwHF1KM5mo",
            "header": "Explore Lear Corporation's strategic adoption of Coupa"
        },{
            "type": "pdf",
            "png": "/pdf/hana/lear.png",
            "url": "/pdf/hana/lear.pdf",
            "header": "Lear case study"
        },{
            "type": "pdf",
            "png": "/pdf/hana/extended.png",
            "url": "/pdf/hana/extended.pdf",
            "header": "Coupa customers with SAP & S/4Hana"
        }]
    },
    "erp_oracle_suite": {
        "label": "Oracle",
        "data": [{
            "type": "video",
            "url": "https://video.vidyard.com/watch/wdDYrAky4C9ad83kjspr7z",
            "header": " This brief overview highlights BMO's journey towards streamlined processes"
        },{
            "type": "pdf",
            "png": "/pdf/oracle/bank.png",
            "url": "/pdf/oracle/bank.pdf",
            "header": "BMO case study"
        },{
            "type": "pdf",
            "png": "/pdf/oracle/lululemon.png",
            "url": "/pdf/oracle/lululemon.pdf",
            "header": "lululemon OPTIS Customer Success Story"
        }]
    },
    "erp_dynamics_365": {
        "label": "Microsoft Dynamics 365",
        "data": [{
            "type": "video",
            "url": "https://video.vidyard.com/watch/P66yUf7c6A7gzDL8CQBUjc",
            "header": " Customer Story: Ionis"
        },{
            "type": "pdf",
            "png": "/pdf/microsoft/ionis.png",
            "url": "/pdf/microsoft/ionis.pdf",
            "header": "Ionis case study"
        },{
            "type": "pdf",
            "png": "/pdf/microsoft/american.png",
            "url": "/pdf/microsoft/american.pdf",
            "header": "American Eagle Success Story"
        }]
    }, 
    "erp_sage_x3": {
        "label": "Sage X3",
        "data": [{
            "type": "video",
            "url": "https://video.vidyard.com/watch/UZTALRVprymwrYfjXqkj7m",
            "header": " Customer Story: Avalon Healthcare"
        },{
            "type": "video",
            "url": "https://video.vidyard.com/watch/1ghkVy6V3qQfTm237dPzjR",
            "header": "Customer Story: Priority Ambulance"
        },{
            "type": "pdf",
            "png": "/pdf/x3/priority.png",
            "url": "/pdf/x3/priority.pdf",
            "header": "Priority Ambulance Case Study"
        },{
            "type": "pdf",
            "png": "/pdf/x3/avalon.png",
            "url": "/pdf/x3/avalon.pdf",
            "header": "Priority Ambulance Healthcare Snapshot"
        }]
    },   
    "erp_netsuite": {
        "label": "Netsuite",
        "data": [{
            "type": "video",
            "url": "https://video.vidyard.com/watch/icuxEMMMU5CcNt65if1TvJ",
            "header": " Customer Story: The Leukemia & Lymphoma Society"
        },{
            "type": "video",
            "url": "https://video.vidyard.com/watch/sia49rpqNgpGBhmtUsXATy",
            "header": "Customer Story: Groupon"
        },{
            "type": "pdf",
            "png": "/pdf/netsuite/coupa.png",
            "url": "/pdf/netsuite/coupa.pdf",
            "header": "Coupa customers with Netsuite"
        },{
            "type": "pdf",
            "png": "/pdf/netsuite/uipath.png",
            "url": "/pdf/netsuite/uipath.pdf",
            "header": "UiPath case study"
        },{
            "type": "pdf",
            "png": "/pdf/netsuite/tech.png",
            "url": "/pdf/netsuite/tech.pdf",
            "header": "Thoughtspot Technology case study"
        }]
    }
}