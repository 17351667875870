{
    "master_data": [],
    "platform": [],
    "purchase_need": [{
        "width": "700px",
        "height": "124px",
        "top": "511px",
        "left": "274px",
        "border-top-left-radius": "20px",
        "border-bottom-left-radius": "20px",
        "border-top-right-radius": "20px",
        "z-index": 20
    }, {
        "width": "265px",
        "height": "334px",
        "top": "635px",
        "left": "724px",
        "border-bottom-left-radius": "20px",
        "border-bottom-right-radius": "20px",
        "border-top-right-radius": "20px",
        "z-index": 30
    }],
    "integration_layer": [],
    "ok_to_pay": [{
        "width": "345px",
        "height": "115px",
        "top": "75px",
        "left": "1066px",
        "border-top-left-radius": "20px",
        "border-top-right-radius": "20px"
    },
    {
        "width": "427px",
        "height": "781px",
        "top": "190px",
        "left": "984px",
        "border-top-left-radius": "20px",
        "border-bottom-left-radius": "20px",
        "border-bottom-right-radius": "20px"
    }]
}