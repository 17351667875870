import PropTypes from "prop-types";
import React, { useState }  from "react";
import ReactModal from 'react-modal';
import ReactPlayer from 'react-player';
import {VideoIcon} from "../../icons/VideoIcon";
import config from './integration-stories.json'
import "./style.css";

export const IntegrationStories = ({ color = "#031B78", className }) => {
  const [isOpen, setOpen] = useState(false);
  const [filterErpValue, setFilterErpValue] = useState('erp_sap_ecc');

  const onButtonClick = (url, type) => {
      fetch(url).then((response) => {
          response.blob().then((blob) => {

              // Creating new object of PDF file
              const fileURL =
                  window.URL.createObjectURL(blob);

              // Setting various property values
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = `SamplePDF.${type}`;
              alink.click();
          });
      });
  };
  const iteractiveContent = (item) => {
    switch(item.type) {

      case "pdf":   return <img className="group pdf" alt="pdf" src={item.png} onClick={()=>onButtonClick(item.url, item.type)} />;
      case "image":   return <img className="group" alt="image" src={item.url} />;
      case "video": return (<div className="group">
        <img className="loading-image " alt="Image" src="img/loading.gif" />
        <ReactPlayer width="100%" height="auto" url={item.url} />
      </div>);

      default:      return <h1>nothing found</h1>
    }
  }

  return (
    <React.Fragment>
      <ReactModal
          isOpen={isOpen}
          contentLabel="onRequestClose Example"
          onRequestClose={() => {setOpen(false);}}
          className="IntegrationModal animate__animated animate__fadeIn"
          ariaHideApp={false}
          parentSelector={
            () => document.getElementById('app')
          }
           shouldFocusAfterRender={false}
           overlayClassName="Overlay"
        >
          <img className="polygon-3" alt="close" src="/img/triangle.svg"/>
          <div className="integration-stories">
            <div className="title">
              <div className="text-wrapper">Select Integration Stories</div>
              <img className="vector" alt="Close" src="/img/x_black.svg" onClick={() => {setOpen(false);}} />
            </div>
            <div className="tags">
              {Object.keys(config).map((key, index) => {
                let selectedClass = (key == filterErpValue) ? "selected" : "";
                return (
                  <div key={index} className={`div-wrapper ${selectedClass}`} onClick={()=> setFilterErpValue(key)}>
                    <div className="text-wrapper-2">{config[key].label}</div>
                  </div>
                )
              })}
            </div>
            <div className="list-item">
              {config[filterErpValue] && config[filterErpValue].data.map((item, index) => {
                return (
                  <div className="frame-2" key={item.header + index}>
                    {iteractiveContent(item)}
                    <p className="members-cashes">{item.header}</p>
                  </div>
                )
              }
              )}
            </div>
          </div>
      </ReactModal>
      <VideoIcon color={color} className={className} click={() => setOpen(true)}></ VideoIcon>
    </React.Fragment>

  );
};

IntegrationStories.propTypes = {
  color: PropTypes.string,
  src: PropTypes.string,
};
