import React from 'react';

export const IconSelectedProject = () => {
  return (
    <svg
      fill="none"
      height="22"
      viewBox="0 0 21 22"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 0.5C13.2848 0.5 15.9555 1.60625 17.9246 3.57538C19.8938 5.54451 21 8.21523 21 11C21 13.7848 19.8938 16.4555 17.9246 18.4246C15.9555 20.3938 13.2848 21.5 10.5 21.5C7.71523 21.5 5.04451 20.3938 3.07538 18.4246C1.10625 16.4555 0 13.7848 0 11C0 8.21523 1.10625 5.54451 3.07538 3.57538C5.04451 1.60625 7.71523 0.5 10.5 0.5ZM9.192 13.0715L6.8595 10.7375C6.77588 10.6539 6.67661 10.5876 6.56736 10.5423C6.4581 10.497 6.34101 10.4737 6.22275 10.4737C6.10449 10.4737 5.9874 10.497 5.87814 10.5423C5.76889 10.5876 5.66962 10.6539 5.586 10.7375C5.41712 10.9064 5.32225 11.1354 5.32225 11.3743C5.32225 11.6131 5.41712 11.8421 5.586 12.011L8.556 14.981C8.63938 15.065 8.73858 15.1317 8.84786 15.1773C8.95715 15.2228 9.07436 15.2462 9.19275 15.2462C9.31114 15.2462 9.42835 15.2228 9.53764 15.1773C9.64692 15.1317 9.74612 15.065 9.8295 14.981L15.9795 8.8295C16.0642 8.74623 16.1316 8.647 16.1778 8.53755C16.224 8.42809 16.2481 8.31057 16.2487 8.19177C16.2492 8.07297 16.2262 7.95523 16.1811 7.84535C16.1359 7.73547 16.0694 7.63562 15.9854 7.55156C15.9015 7.46751 15.8017 7.4009 15.6919 7.3556C15.5821 7.31029 15.4644 7.28718 15.3455 7.28759C15.2267 7.288 15.1092 7.31193 14.9997 7.358C14.8902 7.40407 14.7909 7.47136 14.7075 7.556L9.192 13.0715Z"
        fill="white"
      />
    </svg>

  );
};
