import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { FormIdentity } from '../../components/FormIdentity/FormIdentity';

export const Login = () => {
  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
      <Grid
        container
        justifyContent="center"
        spacing={5}
        sx={{ padding: '2rem', position: 'relative' }}
      >
        <Grid item xs={5}>
          <FormIdentity/>
        </Grid>
        <Grid item xs={12}>
          <Link to="/">
            <Typography align="center" sx={{ color: '#fff' }}>
              Watch Intro Video Again
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
