import { Avatar, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { IconSelectedProject } from '../../icons/IconSelectedProject/IconSelectedProject';
import { useFormState } from '../../providers/formContext.provider';
import { CardProjectStyles } from './CardProject.styles';

export const CardProject = (props) => {
  const {
    id = '',
    imageSource = '',
    text = '',
    title = '',
  } = props;
  const { data, setData } = useFormState();

  const handleOnClick = useCallback(() => {
    setData((prevState) => ({
      ...prevState,
      project: id,
    }));
  }, [id, setData]);

  return (
    <Card sx={CardProjectStyles.card}>
      <Grid container direction="column" justifyContent="space-between" sx={CardProjectStyles.container}>
        <Grid item>
          <CardContent>
            <Grid container spacing={2}>
              <Grid container item justifyContent="center" spacing={8}>
                <Grid item>
                  <Avatar src={imageSource} sx={CardProjectStyles.image}/>
                </Grid>
                <Grid item>
                  <Typography align="center" variant="h4">
                    {title}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography>
                  {text}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
        <Grid item>
          <CardActions sx={CardProjectStyles.cardActions}>
            <Button
              fullWidth
              onClick={handleOnClick}
              size="large"
              startIcon={data.project === id ? <IconSelectedProject/> : null}
              sx={CardProjectStyles.button}
              variant={data.project === id ? 'contained' : 'outlined'}
            >
              {data.project === id ? 'Selected' : 'Select'}
            </Button>
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
};
