import React from "react";
import "./style.css";
import { HeaderBurger } from "../HeaderBurger";

export const Header = () => {
  return (
    <div className="header">
      <img className="logo" alt="Logo" src="/img/logo1.svg" />
      <HeaderBurger></HeaderBurger>
    </div>
  );
};